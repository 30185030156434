export const defaultMeta = [
    {
        vmid: "description",
        name: "description",
        content: "비교하고 고르는 나의 법무 파트너, 법무픽"
    },
    {
        vmid: "keywords",
        name: "keywords",
        content: "부동산, 법인, 회생, 파산, 회생파산, 근저당, 소유권, 설립, 발기, 임원, 변경, 등기소, 이전, 설정, 등기, 일반등기, 전자등기, 바른길, 바른길 합동법무사무소, 미래등기, 미래, 법인등기, 빠른등기, 차세대, 법무통, 법무픽, taxly, 택슬리, 로켓등기, 로톡, 찾아줘 세무사, 헬프미, 인터넷등기소, 스타트업, 중소기업, 벤처, 법원"
    }
]

export const LandingMeta = [
    {
        vmid: "description",
        name: "description",
        content: "비교하고 고르는 나의 법무 파트너, 법무픽"
    },
    {
        vmid: "keywords",
        name: "keywords",
        content: "부동산, 법인, 회생, 파산, 회생파산, 근저당, 소유권, 설립, 발기, 임원, 변경, 등기소, 이전, 설정, 등기, 일반등기, 전자등기, 바른길, 바른길 합동법무사무소, 미래등기, 미래, 법인등기, 빠른등기, 차세대, 법무통, 법무픽, taxly, 택슬리, 로켓등기, 로톡, 찾아줘 세무사, 헬프미, 인터넷등기소, 스타트업, 중소기업, 벤처, 법원"
    }
]

export const NoticeMeta = [
    {
        vmid: "description",
        name: "description",
        content: "법무픽 공지사항"
    },
]