import { createRouter, createWebHistory } from 'vue-router'

import { PATH } from '@/utils/path.router'
import { toHandlerKey } from 'vue'

const routes =  [
        {   
            path: PATH.landing.index,
            name: "LandingPage",
            component: () => (import('@/views/main/LandingPage.vue')),
        },
        {
            path: '/download',
            name: "DownloadPage",
            component: () => (import('@/views/main/DownloadPage.vue'))
        },
        {
            path: PATH.notice.index,
            name: "NoticeIndexPage",
            component: () => (import('@/views/notice/NoticeIndexPage.vue'))
        },
        {
            path: PATH.notice.detail,
            name: "NoticeDetailPage",
            component: () => (import('@/views/notice/NoticeDetailPage.vue'))
        },
        {
            path:'/:version/payment',
            name: "Payment",
            component: () => (import('@/views/Payment.vue'))
        },
        {
            path:'/:version/payment/success',
            name: "Success",
            component: () => (import('@/views/Success.vue'))
        },
        {
            path:'/:version/payment/success/return',
            name: "SuccessReturn",
            component: () => (import('@/views/SuccessReturn.vue'))
        },
        {
            path:'/:version/payment/fail',
            name: "Fail",
            component: () => (import('@/views/Fail.vue'))
        },
        /**
         * App Phone Auth router
         */
        {
            path: PATH.appAuthPhone.index,
            name: 'AppPhoneAuthIndexPage',
            component: () => (import('@/views/nice/ServicePhoneAuthIndex.vue')),
        },
        {
            path: PATH.appAuthPhone.receiver,
            name: 'AppPhoneAuthReceiverPage',
            component: () => (import('@/views/nice/ServicePhoneAuthReceiver.vue')),
        },
        { 
            path: "/:pathMatch(.*)*", 
            name: 'NotFound',
            component: () => (import('@/views/NotFound.vue'))
        },
    ]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export const routerAlert = () => {
    alert('잘못된 접근입니다')
    router.push(PATH.landing.index)
}

export default router;