<template>
    <metainfo></metainfo>
    <router-view></router-view>
</template>

<script>
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { defaultMeta } from '@/utils/util.meta'
export default defineComponent({
    name: 'app',
    data: () => {
        return {
            phase: ""
        }
    },
    setup(){
        useMeta({
            meta: defaultMeta
        })
    },
    computed:{
    },
    mounted() {
        this.phase = process.env.VUE_APP_PHASE
    }
})
</script>