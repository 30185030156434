// import vue modules
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createMetaManager, defaultConfig } from 'vue-meta'
import VueGtag from 'vue-gtag-next'

// import Constant
import './assets/css/index.scss'
import 'animate.css'

const app = createApp(App)

app.use(createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: 'meta', nameless: true },
}))

app.use(VueGtag, {
    property:{
        id: 'AW-11084349736',
        disableScriptLoader: true
    }
})

app.use(router)
app.mount('#app')