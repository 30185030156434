


const BaseUrl = {
    appAuthPhone: '/app/auth/nice',
    notice: '/notice'
}

export const PATH = {
    landing: {
        index: '/',
    },
    notice: {
        index: `${BaseUrl.notice}`,
        detail: `${BaseUrl.notice}/detail/:noticeId`
    },
    // - PHONE AUTH
    appAuthPhone: {
        index: `${BaseUrl.appAuthPhone}/index`,
        receiver: `${BaseUrl.appAuthPhone}/receiver`,
    },
}